import { HiOutlineExclamationCircle } from 'react-icons/hi';
import EventImage from './EventImage';

export default function Bonus() {
  return (
    <div className="event-bonus">
      <div className="event-bonus__text">
        <div className="event-bonus__text__discount">
          <HiOutlineExclamationCircle />
          <h3 className="h6">
            We are announcing our new Master of Arts in Pastoral Theology!
          </h3>
        </div>
      </div>
      <div className="event-bonus__img hide-xs show-sm">
        <EventImage />
      </div>
    </div>
  );
}
